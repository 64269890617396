import 'styles/globals.scss'
import 'styles/documents/index.scss'

import type {AppProps} from 'next/app'
import {Lato} from 'next/font/google'
import Head from 'next/head'
import Script from 'next/script'

import ErrorBoundary from 'components/blocks/ErrorBoundary'
import ErrorBoundaryFallback from 'components/blocks/ErrorBoundaryFallback/ErrorBoundaryFallback'
import LayoutDefault from 'components/layouts/LayoutDefault'
import RouteProgressBar from 'src/components/blocks/RouteProgressBar'
import {loadPolyfills} from 'src/lib/polyfills'
import {TPageComponent} from 'src/types'
import AuthIndicator from 'src/utils/auth/AuthIndicator'
import {RootStoreProvider} from 'src/utils/contexts/RootStore.context'
import useMobileDetect from 'src/utils/hooks/useMobileDetect'
import {useTrafficParamsSaver} from 'src/utils/hooks/useTrafficParamsSaver'
import initAuth from 'src/utils/initAuth'

loadPolyfills()

initAuth()

const lato = Lato({
	weight: ['400', '700'],
	display: 'swap',
	subsets: ['latin'],
	variable: '--font-lato',
})

function MyApp({
	Component,
	pageProps,
}: AppProps & {
	Component: TPageComponent
}) {
	const Layout = Component.Layout || LayoutDefault
	const {isIos} = useMobileDetect()
	useTrafficParamsSaver()

	return (
		<div className={`layout-wrapper ${lato.variable}`}>
			<Head>
				{/* added to fix auto zooming when user enters data into inputs on the IOS */}
				<meta
					name="viewport"
					content={`width=device-width, initial-scale=1${isIos() ? `, maximum-scale=1` : ''}`}
				/>
			</Head>
			<Script
				id="GTM"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','GTM-TSPMC9G');`,
				}}
			/>
			<Script id="posthog-script" strategy="afterInteractive">
				{`
				function getCookie(name) {
					const cookies = document.cookie.split('; ');
					const cookiePair = cookies.find(cookie => cookie.startsWith(name + '='));
					if (cookiePair) {
							const cookieValue = cookiePair.split('=')[1];
							return cookieValue;
					}
					return null;
				}
				function setCookie(name, value) {
					document.cookie = name + "=" + (value || "") + "; path=/";
				}
				!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
    		posthog.init('${process.env.NEXT_PUBLIC_POSTHOG_API_KEY}', {api_host: 'https://app.posthog.com', autocapture: false, capture_pageleave: false,capture_pageview: false,bootstrap: { distinctID: undefined, isIdentifiedID: true},loaded: (posthog) => {
				if (!sessionStorage.getItem('pageview')) {
				posthog.capture('$pageview')
				sessionStorage.setItem('pageview', 'true')
			} setCookie('distinctId', posthog.get_distinct_id())}})`}
			</Script>

			<ErrorBoundary fallback={<ErrorBoundaryFallback />}>
				<RootStoreProvider>
					<AuthIndicator>
						<Layout>
							<RouteProgressBar />
							<Component {...pageProps} />
						</Layout>
					</AuthIndicator>
				</RootStoreProvider>
			</ErrorBoundary>
		</div>
	)
}

export default MyApp
